<!-- src/components/MainLayout.vue -->
<template>
  <div class="min-h-full bg-palladian">
  	<Header></Header>
    <header class="shadow border-t-2 border border-palladian bg-palladian">
      <div class="mx-auto max-w-7xl px-2 sm:px-4">
      <div class="flex justify-between items-center">
        <!-- Wrap the title in a div for background styling -->
        <div class="inline-block text-abyssal-blue text-3xl font-bold tracking-tight px-3 pb-1 mt-0">
          {{ currentTitle }}
        </div>
        <!-- Display the logged-in user's email outside the bg-gray-900 area -->
        <span v-if="userEmail" class="text-xs text-abyssal-blue">Logged in as {{ userEmail }} | <button type="button" @click="logout">Log Out</button></span>
      </div>
    </div>
  </header>

	<!-- <Banner></Banner> -->
  <main class="bg-palladian px-4">
    <div class="mx-auto max-w-7xl px-4 sm:px-4 py-2 sm:py-3 border border-palladian">
		<router-view></router-view>
	  </div>
	</main>
  </div>
</template>

<script setup>
import Header from '@/components/Header.vue';
import Banner from '@/components/Banner.vue';
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { auth } from '@/firebase.js'; // Ensure this path is correct

const route = useRoute();
const router = useRouter();


const currentTitle = computed(() => route.meta.title || 'Default Title');

const userEmail = ref(null);

onMounted(() => {
  auth.onAuthStateChanged(user => {
    userEmail.value = user ? user.email : null;
  });
});

const logout = async () => {
  try {
    await auth.signOut(); // Log out the user
    router.push('/login'); // Redirect to /loginQR after logging out
  } catch (error) {
    console.error("Logout failed:", error);
    // Handle logout errors here, e.g., show an error message
  }
};
</script>