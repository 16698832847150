import { defaultConfig, defineFormKitConfig } from '@formkit/vue';
import { createLocalStoragePlugin, createFloatingLabelsPlugin } from '@formkit/addons';
import { rootClasses } from './formkit.theme.mjs'
import { barcode } from '@formkit/barcode'
import '@formkit/barcode/genesis'
import { createProPlugin, autocomplete, datepicker, togglebuttons, slider } from '@formkit/pro'

const proPlugin = createProPlugin('fk-8d7089210d', {
  autocomplete,
  datepicker,
  togglebuttons,
  slider,
})

import '@formkit/addons/css/floatingLabels'

const additionalConfig = defineFormKitConfig({
  config: {
    rootClasses,
  },
});

const config = defaultConfig({
  ...additionalConfig, // Spread the additional configuration
  plugins: [
    [proPlugin],
    createLocalStoragePlugin({
      // Optional configuration options
      prefix: 'formkit', // Default prefix for localStorage keys
      key: undefined, // Optional unique key for differentiating users
      control: undefined, // Optional field name to control saving to localStorage
      maxAge: 3600000, // Data validity period in milliseconds (default 1 hour)
      debounce: 200, // Debounce time in milliseconds for saving data
      beforeSave: undefined, // Optional callback before saving data
      beforeLoad: undefined // Optional callback before loading data
    }),
    createFloatingLabelsPlugin({
      useAsDefault: false, // defaults to false
    }),
  ],
  config: {
    rootClasses,
  },
  inputs: {
    barcode
  }
})

export default config