<!-- EventLayout.vue -->
<template>
    <div class="min-h-full">
      <main class="bg-gray-50">
          <router-view></router-view>
      </main>
    </div>
  </template>
  
  <script setup>
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';
  
  const route = useRoute();
  
  const currentTitle = computed(() => route.meta.title || 'Default Title');
  
  </script>