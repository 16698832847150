<!-- src/views/SignIn.vue -->
<template>

 <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
<div class="sm:mx-auto sm:w-full sm:max-w-sm">
  <img class="mx-auto h-20 w-auto" src="../assets/83.svg" alt="83 Islands Distillery" />
  <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">Sign in with QR Code</h2>
  <div class="flex justify-center">
    <button 
      type="button" 
      @click="() => router.push('/loginQR')"
      class="mt-4 flex w-1/2 justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
      Dashboard - Scan QR
    </button></div>
	<div class="flex justify-center">
    <button 
      type="button" 
      @click="() => router.push('/loginQRPOS')"
      class="mt-4 flex w-1/2 justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
      Point of Sale - Scan QR
    </button></div>

  <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">Sign in to your account</h2>
</div>

<div class="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
  <form class="space-y-6" @submit.prevent="signIn">
	<div>
		  <label for="email" class="block text-sm font-medium leading-6 text-white">Email address</label>
		  <div class="mt-2">
			<input v-model="email" id="email" name="email" type="email" autocomplete="email" required class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
		  </div>
		</div>

		<div>
		  <div class="flex items-center justify-between">
			<label for="password" class="block text-sm font-medium leading-6 text-white">Password</label>
			<div class="text-sm">
			  <a href="#" class="font-semibold text-indigo-400 hover:text-indigo-300">Forgot password?</a>
			</div>
		  </div>
		  <div class="mt-2">
			<input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
		  </div>
		</div>

		<div>
		  <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Sign in</button>
		</div>
	  </form>

	  <p class="mt-10 text-center text-sm text-gray-400">
		
	  </p>
	</div>
  </div>
</template>


<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { auth } from '@/firebase.js';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useAuthStore } from '../stores/auth';
import { authState } from '../router/router'; // Import authState

const email = ref('');
const password = ref('');

const router = useRouter();
const authStore = useAuthStore();

async function signIn() {
  try {
    await signInWithEmailAndPassword(auth, email.value, password.value);
    handleSuccessfulLogin();
  } catch (error) {
    console.error(error);
  }
}
const handleSuccessfulLogin = () => {
  const redirectPath = authState.redirectPath || '/dashboard';
  console.log('Successful login, redirecting to:', redirectPath); // Debug log
  authState.clearRedirectPath();
  router.push(redirectPath);
};
</script>
