import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import { doc, getDoc } from "firebase/firestore";



// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
};
// console.log('Starting Firebase initialization with config:', firebaseConfig);

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// console.log('Firebase has been initialized with app:', app);

// Initialize Firebase Auth
const auth = getAuth(app);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Storage
const storage = getStorage(app);

// Function to get user role
const getUserRole = async (userId) => {
  const userRef = doc(db, "users", userId); // Assuming 'users' is your collection
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    return userSnap.data().role; // Assuming the role is stored in the 'role' field
  } else {
    console.log("No such user!");
    return null;
  }
};


// console.log('Firebase services have been initialized with auth:', auth, ', db:', db, ', storage:', storage);


export { auth, db, storage, getUserRole };