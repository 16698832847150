import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    redirectPath: null,
  }),
  actions: {
    setRedirectPath(path) {
      this.redirectPath = path;
    },
    clearRedirectPath() {
      this.redirectPath = null;
    },
  },
});