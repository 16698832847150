// src/stores/DutyFree.js
import { defineStore } from 'pinia';
import axios from 'axios';

export const useDutyFreeStore = defineStore({
  id: 'dutyFree',
  state: () => ({
    list: [],
    loading: false, // Add this line
  }),
  actions: {
    setProducts(newProducts) {
      this.list = newProducts;
    },
    async fetchProducts() {
        if (this.list.length > 0) {
            console.log('Products already fetched, skipping API call.');
            return;
        }
      this.loading = true;
      try {
        const response = await axios.get('/api/productsFromMongoDB', {
          params: {
            category: "Duty Free"
          }
        });
        const filteredProducts = response.data.filter(
          product => product.Categories && product.Categories.includes("Duty Free")
        );
        this.setProducts(filteredProducts);
      } catch (error) {
        console.error('Error fetching product data:', error);
        if (error.response) {
          console.log('Response data:', error.response.data);
          console.log('Response status:', error.response.status);
          console.log('Response headers:', error.response.headers);
        } else if (error.request) {
          console.log('Request data:', error.request);
        } else {
          console.log('Error', error.message);
        }
      }
      this.loading = false;
    },
  },
});