<!-- src/components/MainLayout.vue -->
<template>
  <div class="min-h-full ">
  	<Header></Header>
	<header class="bg-white shadow">
	  <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
		<h1 class="text-3xl font-bold tracking-tight text-gray-900">{{ currentTitle }}</h1>
	  </div>
	</header>
	<!-- <Banner></Banner> -->
	<main class="bg-gray-50">
	  <div class="mx-auto py-6 sm:px-6 lg:px-8">
		<router-view></router-view>
	  </div>
	</main>
  </div>
</template>

<script setup>
import Header from '@/components/Header.vue';
import Banner from '@/components/Banner.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const currentTitle = computed(() => route.meta.title || 'Default Title');

const components = { Header,Banner };
</script>