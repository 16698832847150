<template>
    <div class="container mx-auto p-4">
      <h1 class="text-2xl font-bold mb-4">Delivery Confirmed</h1>
      <p>Manifest Number: {{ manifestNumber }}</p>
      <p>Date and Time: {{ deliveryDate }}</p>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  import { useRoute } from 'vue-router';
  
  const route = useRoute();
  const manifestNumber = ref(route.params.manifestNumber);
  const deliveryDate = ref('');
  
  onMounted(async () => {
    try {
      const response = await axios.get(`/api/manifest/delivery/${manifestNumber.value}`);
      deliveryDate.value = response.data.deliveryDate;
    } catch (error) {
      console.error('Error fetching delivery confirmation:', error);
    }
  });
  </script>
  
  <style scoped>
  .container {
    max-width: 600px;
  }
  </style>