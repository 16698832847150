<!-- src/components/MainLayout.vue -->
<template>
  <div class="min-h-full ">

	<header class="bg-white shadow">
	</header>
	<main class="bg-gray-50">
	  <div class="mx-auto py-2 sm:px-6 lg:px-8">
		<router-view></router-view>
	  </div>
	</main>
  </div>
</template>

<script setup>

import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();


</script>